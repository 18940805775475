import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { Layout, Listing, Wrapper, Title } from '../components'
import website from '../../config/website'

const Hero = styled.header`
  background-color: ${props => props.theme.colors.greyLight};
  display: flex;
  align-items: center;
`

const HeroInner = styled(Wrapper)`
  padding-top: 4rem;
  padding-bottom: 0rem;
  h1 {
    margin-bottom: 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding-top: 3rem;
    padding-bottom: 0rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding-top: 3rem;
    padding-bottom: 0rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding-top: 2rem;
    padding-bottom: 0rem;
  }
`

const HeroText = styled.div`
  font-size: 1.2rem;
  line-height: 1.4;
  margin-bottom: 2rem;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.1rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.1rem;
  }
`

const Social = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  li {
    display: inline;
    &:not([data-name='social-entry-0']) {
      margin-left: 2.5rem;
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        margin-left: 1.75rem;
      }
    }
    a {
      font-style: normal;
      color: ${props => props.theme.colors.greyDark};
      font-size: 1.333rem;
      font-weight: 600;
      &:hover,
      &:focus {
        color: ${props => props.theme.colors.primary};
        text-decoration: none;
      }
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        font-size: 1.2rem;
      }
    }
  }
`

const ProjectListing = styled.ul`
  list-style-type: none;
  margin-left: 0;
  margin-top: 4rem;
  li {
    margin-bottom: 1.45rem;
    a {
      font-size: 2rem;
      font-style: normal;
      color: ${props => props.theme.colors.black};
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        font-size: 1.777rem;
      }
    }
  }
`

const IndexWrapper = Wrapper.withComponent('main')

class Index extends Component {
  render() {
    const {
      data: { homepage, social, posts, projects },
    } = this.props
    return (
      <Layout>
        <Hero>
          <HeroInner>
            <h1>{homepage.data.title.text}</h1>
            <HeroText dangerouslySetInnerHTML={{ __html: homepage.data.content.html }} />
            <Social>
              {social.edges.map((s, index) => (
                <li data-name={`social-entry-${index}`} key={s.node.primary.label.text}>
                  <a href={s.node.primary.link.url}>{s.node.primary.label.text}</a>
                </li>
              ))}
            </Social>
          </HeroInner>
        </Hero>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1431.6 168.4">
    <defs />
    <polyline className="a" points="0 155.58 0 0 1431.6 0 1431.6 155.58" fill="#eaf6f6"/>
    <path className="b" d="M0,144.12V82.5C46.71,58.21,95.44,37,146.65,24.35c61.57-15.21,123.84-23,187.29-22.26C398.1,2.8,461.27,12,523.72,26.41,588.09,41.23,651.19,60.9,714.6,79.27c66.56,19.29,133.59,37,202,48.25,46.9,7.74,94.87,11,142.2,5.22a378.49,378.49,0,0,0,85.63-20.85c36.4-13.5,67.07-29,95.16-56.38l19.29-18.77,19,19.06A269.42,269.42,0,0,0,1423,131c2.86.5,5.73.94,8.6,1.34v30.5"
    transform="translate(0 .59)" fill="#ffffff"/>
    <path className="a" d="M0,148.56V101.41C106.2,34.08,217.7,8.62,345.27,12c127,3.39,243.85,48.61,364.59,83.6,67.39,19.53,134.67,37.25,204,48.69,48.63,8,98,11.31,147,5.33,74.17-9,158.13-47.3,198.3-98.89,38.25,48.29,95.45,85.77,160.93,97q5.73,1,11.49,1.74v18.27"
    transform="translate(0 .59)" fill="#eaf6f6"/>
    <path className="b" d="M0,155V109.47c48.65-26,99.57-48.92,152.37-62,295.72-73,484.21,58,760.36,103.55,49.46,8.16,99.29,11.42,149,5.36h0a402,402,0,0,0,91-22.16c40.25-14.92,72.8-31.78,103.51-61.65l2.4-2.34,2.38,2.38A293.19,293.19,0,0,0,1419,154.52q6.3,1.08,12.64,1.89v11.4"
    transform="translate(0 .59)" fill="#ffffff"/>
</svg>
        <IndexWrapper id={website.skipNavId} style={{ paddingTop: '0rem', paddingBottom: '2rem' }}>
          <Title style={{ marginTop: '2rem' }}>Recent posts</Title>
          <Listing posts={posts.edges} />
          <Title style={{ marginTop: '6rem' }}>Recent projects</Title>
          <ProjectListing>
            {projects.edges.map(project => (
              <li key={project.node.primary.label.text}>
                <a href={project.node.primary.link.url}>{project.node.primary.label.text}</a>
              </li>
            ))}
          </ProjectListing>
        </IndexWrapper>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1190.55 191.92">
    <defs />
    <path className="a" d="M0,190.55a.83.83,0,0,1,.83-.83H1040.58c-.55-1.41-1.08-2.85-1.59-4.31-19.47.8-39.53.67-55.36-1.56a.79.79,0,0,1-.62-.44l-4.1-7.85a.84.84,0,0,1,.05-.85.82.82,0,0,1,.77-.36c.42,0,34.84,3.71,57.08,4.05-1.29-4.61-2.37-9.41-3.25-14.35h-38.2a.83.83,0,0,1-.83-.83v-4.1a.83.83,0,0,1,.83-.83h8.24L1041,15.44a.83.83,0,0,1,1.62.39,567,567,0,0,0-9.34,64.54c-2.07,24-3,54.92,1.66,82h12.86L1044.4.3A.83.83,0,0,1,1046,0l62.51,172.84a.83.83,0,0,1-.17.85.81.81,0,0,1-.83.23c-.31-.08-30.38-8.31-53.38,3a.83.83,0,0,1-1.2-.77l.41-12.12h-3.85l.32,14.86a.83.83,0,0,1-.76.85c-2.72.25-6.15.35-10,.33.37,1.22.75,2.42,1.14,3.61,36.16-1.55,70-6.2,70.54-6.27a.82.82,0,0,1,.81.36.84.84,0,0,1,0,.88c-3,5.17-8.65,9-12.1,11.05h90.29a.83.83,0,0,1,0,1.66h-93.59a.83.83,0,0,1-.81-.65.83.83,0,0,1,.45-.94c.1,0,8.71-4.11,13.35-10.48-7.48,1-37,4.66-68.4,6,.59,1.66,1.21,3.3,1.86,4.89a.81.81,0,0,1-.08.78.82.82,0,0,1-.69.37H.83A.83.83,0,0,1,0,190.55Zm1037.29-10.48c-19.7-.27-49.17-3.19-56.19-3.91l3.18,6.11c15.54,2.14,35.1,2.27,54.16,1.5C1038,182.56,1037.65,181.32,1037.29,180.07Zm10.55-16h-12.6c.9,4.95,2,9.76,3.3,14.37,3.66,0,6.94,0,9.6-.25Zm58.59,7.89L1046.17,5.31l3.3,157.08h3.94l4-116.7a.83.83,0,0,1,1.65-.07l13.67,117.5a.82.82,0,0,1-.21.65.8.8,0,0,1-.62.28H1055l-.36,10.81c9.29-4.26,19.57-5.55,28.61-5.55A106.11,106.11,0,0,1,1106.43,171.94Zm-74.79-91.71c1.42-16.51,3.51-31.51,5.36-42.91l-32,122a.81.81,0,0,1-.8.62h-8v2.44h37.08C1028.63,135.18,1029.56,104.27,1031.64,80.23Zm27-23.43-3.59,105.59h15.87Z"
    transform="translate(0 .55)" fill="#6260df"/>
</svg>
      </Layout>
    )
  }
}

export default Index

Index.propTypes = {
  data: PropTypes.shape({
    posts: PropTypes.object.isRequired,
  }).isRequired,
}

export const pageQuery = graphql`
  query IndexQuery {
    homepage: prismicHomepage {
      data {
        title {
          text
        }
        content {
          html
        }
      }
    }
    social: allPrismicHeroLinksBodyLinkItem {
      edges {
        node {
          primary {
            label {
              text
            }
            link {
              url
            }
          }
        }
      }
    }
    posts: allPrismicPost(sort: { fields: [data___date], order: DESC }) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            date(formatString: "DD.MM.YYYY")
            categories {
              category {
                document {
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
    projects: allPrismicProjectsBodyLinkItem {
      edges {
        node {
          primary {
            label {
              text
            }
            link {
              url
            }
          }
        }
      }
    }
  }
`
